import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_member-care')({
  component: Outlet,
  beforeLoad: async ({ context: { auth } }) => {
    const isMemberCare = auth?.isMemberCare;
    const isOnboarded = auth?.isOnboarded;

    if (!isMemberCare) {
      throw redirect({
        to: '/',
      });
    }

    if (isMemberCare && !isOnboarded) {
      throw redirect({ to: '/onboarding/member-care' });
    }
  },
});
