import { useActivatedUsers, useUserDeactivation } from '@/hooks/user/users';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import UsersTable from './UsersTable';
import { svgIconPaths } from '@/assets/svg/icons';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { TUserActivationToggle, TUserFilterOption } from '@/types/User.types';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';

interface Props {
  filterOption?: TUserFilterOption;
  filterText: string;
  setIsLoadingSearch: Dispatch<SetStateAction<boolean>>;
}

const ActivatedUsersTable = ({ filterOption, filterText, setIsLoadingSearch }: Props) => {
  const { isLoading, data, pagination, refetch, isRefetching } = useActivatedUsers({ filterOption, filterText });
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [deactivatingUser, setDeactivatingUser] = useState<TUserActivationToggle>();

  const { mutate, isPending } = useUserDeactivation();

  const isSearching = useMemo(
    () => filterOption !== undefined && filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterOption, filterText, isRefetching],
  );

  useEffect(() => {
    setIsLoadingSearch(isSearching);
  }, [isSearching]);

  const onDeactivationSuccess = () => {
    setIsModalOpened(false);
    refetch();
  };

  const deactivateSelectedUser = () => {
    if (deactivatingUser) {
      mutate(
        { userId: deactivatingUser.id, hasLastLogin: deactivatingUser.last_login !== '' },
        { onSuccess: onDeactivationSuccess },
      );
    }
  };

  return (
    <>
      <UsersTable
        users={data}
        pagination={pagination}
        isLoading={isLoading}
        isRefetching={isRefetching}
        invalidatedUserId={deactivatingUser?.id}
        isSearching={isSearching}
        ActionIcon={(userToDeactivate: TUserActivationToggle) => (
          <CustomButton
            isTransparent
            label={'Deactivate'}
            hoverBackgroundColor="extra.red"
            labelColor={colors.text.mediumBlue}
            labelHoverColor={colors.extra.white}
            iconBeforeLabelSvgPath={svgIconPaths.userDeactivate}
            onClick={() => {
              setIsModalOpened(true);
              setDeactivatingUser(userToDeactivate);
            }}
            width={'120px'}
            height={'35px'}
            noShadow
          />
        )}
      />
      <ConfirmationModal
        isOpen={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
          setDeactivatingUser(undefined);
        }}
        title="Confirm Deactivation"
        confirmButtonText="Deactivate"
        description={`This will deactivate user ${deactivatingUser?.firstName} ${deactivatingUser?.lastName}`}
        onConfirm={deactivateSelectedUser}
        confirmButtonColor="extra.red"
        confirmLoading={isPending}
      />
    </>
  );
};

export default ActivatedUsersTable;
