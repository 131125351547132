import { createContext, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { api } from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { signInPost, userGet } from '@/services/api/requests/user';
import COOKIE_KEYS from '@/constants/cookies';
import { TUser } from '@/types/User.types';
import roles from '@/constants/roles';
import { useAppStore } from '@/store/useAppStore';
import LoadingPage from '@/components/templates/LoadingPage';
import useToast from '@/hooks/useToast';

export type TAuthContext = {
  user: TUser | null;
  isAuthenticated: boolean;
  isSuperAdmin: boolean;
  isMemberCare: boolean;
  isOnboarded: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
};

export const AuthContext = createContext<TAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, setUser } = useAppStore();
  const toast = useToast();

  const isAuthenticated = !!user;
  const isSuperAdmin = !!user?.roles.includes(roles.superAdmin);
  const isMemberCare = !!user?.roles.includes(roles.memberCare);

  // superadmin is onboarded by default, and membercare only if their sex is existent
  const isOnboarded = isSuperAdmin || (isMemberCare && !!user?.user_metadata.sex);

  const handleUserDataFlow = async () => {
    const userData = await userGet();

    const allowedRoles = [roles.memberCare, roles.superAdmin];
    const userRoles = userData.roles;
    const userAllowedToSignIn = allowedRoles.some((role) => userRoles.includes(role));

    if (!userAllowedToSignIn) {
      toast({
        status: 'error',
        title: 'Wrong Platform',
        description: 'Proceed to https://app.myomnia.health/',
      });

      signOut();

      return;
    }

    setUser(userData);
  };

  const signIn = useCallback(async (email: string, password: string) => {
    await signInPost(email, password);

    await handleUserDataFlow();
  }, []);

  const { isPending } = useQuery({
    queryKey: ['user-auth'],
    queryFn: async () => {
      // Get token form cookie if exists
      const token = Cookies.get(COOKIE_KEYS.ACCESS_TOKEN);

      if (!token) {
        return null;
      }

      // Add token to api headers
      api.defaults.headers.Authorization = token;

      await handleUserDataFlow();

      return null;
    },
  });

  const signOut = useCallback(async () => {
    Cookies.remove(COOKIE_KEYS.ACCESS_TOKEN);
    api.defaults.headers.Authorization = '';
    /**
     * On logout reset all persisted stores. If more stores are added, we can reset them in one go
     * see https://zustand.docs.pmnd.rs/guides/how-to-reset-state for more details if needed
     */
    useAppStore.getState().reset();
  }, []);

  const auth = useMemo(() => {
    return {
      isAuthenticated,
      user,
      isSuperAdmin,
      isMemberCare,
      isOnboarded,
      signIn,
      signOut,
    };
  }, [isAuthenticated, user, signIn, signOut, isSuperAdmin, isMemberCare, isOnboarded]);

  return <AuthContext.Provider value={auth}>{isPending ? <LoadingPage /> : children}</AuthContext.Provider>;
};
