import Header from '@/components/molecules/Header';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { Flex, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

const AppPageTemplate = ({ children }: PropsWithChildren) => {
  return (
    <Flex width={'100%'} direction={'column'} alignItems={'center'}>
      <Header />
      <VStack marginTop={'40px'} maxWidth={MAX_CONTAINER_WIDTH}>
        {children}
      </VStack>
    </Flex>
  );
};

export default AppPageTemplate;
