import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';
import { TMemberTimelineType } from '@/types/MemberCare.types';

const getTimelineIconFromType = (timelineType: TMemberTimelineType) => {
  console.log('timelineType', timelineType);
  // if (i % 4 === 0) {
  return (
    <SvgIcon iconPath={svgIconPaths.assessment} color={colors.text.mediumBlue} size={25} boxWidth={18} boxHeight={17} />
  );
  // }

  // if (i % 4 === 1) {
  //   return (
  //     <SvgIcon iconPath={svgIconPaths.phone} color={colors.text.mediumBlue} size={25} boxWidth={18} boxHeight={17} />
  //   );
  // }
  // if (i % 4 === 2) {
  //   return (
  //     <SvgIcon iconPath={svgIconPaths.message} color={colors.text.mediumBlue} size={25} boxWidth={14} boxHeight={15} />
  //   );
  // }
  // if (i % 4 === 3) {
  //   return <Image alt="date-input-calendar" src={svgIcons.calendar} height={'16px'} width={'16px'} />;
  // }
};

export default getTimelineIconFromType;
