import logoVariants from '@/assets/img/logo';
import iconsPng from '@/assets/img/png/icons';
import LoadingPage from '@/components/templates/LoadingPage';
import authRoutes from '@/constants/routes/auth';
import useToast from '@/hooks/useToast';
import onboardingMessages from '@/messages/onboarding';
import { Image, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useState } from 'react';
import roles from '../../constants/roles.ts';
import { CLIENT_APP_SIGN_IN_URL } from '@/constants/stringVars.ts';
import BlueTopPage from '@/components/templates/BlueTopPage/index.tsx';
import NewPasswordForm from './NewPasswordForm.tsx';

interface Props {
  pageTitle: string;
  pageDescription: string;
  email: string;
  authToken: string;
  verifyTokenCallback: () => Promise<{ success: boolean }>;
  setPasswordCallback: (password: string) => Promise<{ success: boolean }>;
  userRoles: string[];
}

const UserPasswordPage = ({
  pageTitle,
  pageDescription,
  email,
  authToken,
  verifyTokenCallback,
  setPasswordCallback,
  userRoles,
}: Props) => {
  const [initIsLoading, setInitIsLoading] = useState(true);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);

  const [isPasswordSubmitLoading, setIsPasswordSubmitLoading] = useState(false);
  const [passwordSubmitSuccessful, setPasswordSubmitSuccessful] = useState(true); // initially true so that we can set it to false if something goes wrong

  const navigate = useNavigate();
  const toast = useToast();

  const verifyAuthTokenWithEmail = useCallback(async () => {
    if (!authToken) {
      setInitIsLoading(false);
      setVerificationSuccessful(false);
    } else {
      const { success } = await verifyTokenCallback();
      setVerificationSuccessful(success);
      setInitIsLoading(false);
    }
  }, [authToken, email]);

  useEffect(() => {
    verifyAuthTokenWithEmail();
  }, []);

  const onSubmitPasswordForm = async (password: string) => {
    setIsPasswordSubmitLoading(true);

    const { success } = await setPasswordCallback(password);

    if (success) {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Password change successful!',
        position: 'top-right',
      });

      if (userRoles.includes(roles.orgEmployee) || userRoles.includes(roles.orgAdmin)) {
        window.location.replace(CLIENT_APP_SIGN_IN_URL);
      } else {
        navigate({ to: '/sign-in' });
      }
    } else {
      setPasswordSubmitSuccessful(false);
    }

    setIsPasswordSubmitLoading(false);
  };

  if (initIsLoading) return <LoadingPage />;

  if (!verificationSuccessful) {
    return (
      <VStack paddingTop={'50px'}>
        <Image
          src={logoVariants.purple252x80}
          alt="my-omnia"
          _hover={{ cursor: 'pointer' }}
          width={'126px'}
          height={'40px'}
          onClick={() => {
            navigate({ to: authRoutes.signIn });
          }}
        />
        <VStack paddingTop={'30px'}>
          <Image
            alt="edit-icon"
            src={iconsPng.orangeExclamationMark}
            width={'120px'}
            height={'120px'}
            objectFit="cover"
            style={{ margin: '40px 0' }}
          />
          <Text textAlign={'center'} variant="loraTitle" color="text.mediumBlue" fontSize={'26px'}>
            {onboardingMessages.emailNotVerifiedSuccessfully}
          </Text>
          <Text
            textAlign={'center'}
            color={'text.mediumBlue'}
            opacity={0.7}
            lineHeight={'20px'}
            fontWeight={'600'}
            paddingTop={'20px'}
          >
            {onboardingMessages.checkEmailOrContactAdmin}
          </Text>
        </VStack>
      </VStack>
    );
  }

  return (
    <BlueTopPage title={pageTitle} description={pageDescription} hideButtons centeredLogo>
      <VStack>
        {verificationSuccessful && (
          <NewPasswordForm onSubmit={onSubmitPasswordForm} isLoading={isPasswordSubmitLoading} />
        )}

        {!passwordSubmitSuccessful && <Text variant={'error'}>{onboardingMessages.passwordSetupUnsuccessful}</Text>}
      </VStack>
    </BlueTopPage>
  );
};

export default UserPasswordPage;
