import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import { svgIconPaths } from '@/assets/svg/icons';
import colors from '@/theme/colors';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

interface Props {
  setFilterText: Dispatch<SetStateAction<string>>;
  isSearching?: boolean;
}

const SearchInputBox = ({ setFilterText, isSearching }: Props) => {
  const [searchActive, setSearchActive] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [localSearchText, setLocalSearchText] = useState('');

  const debouncedSetFilterText = debounce((value) => setFilterText(value), 400);

  useEffect(() => {
    debouncedSetFilterText(localSearchText);
  }, [localSearchText]);

  return (
    <VStack marginLeft={searchActive ? '10px' : '24px'}>
      <InputGroup>
        <InputLeftElement
          cursor={'pointer'}
          onClick={() => {
            setSearchActive(true);
            setTimeout(() => searchInputRef.current?.focus(), 0);
          }}
        >
          <SvgIcon
            iconPath={svgIconPaths.magnifyingGlass}
            color={searchActive ? colors.text.mediumGray : colors.text.darkBlue}
          />
          {!searchActive && (
            <Text variant={'urbanistBold'} color={'text.darkBlue'} marginLeft={'10px'}>
              Search
            </Text>
          )}
        </InputLeftElement>
        <Input
          maxWidth={searchActive ? '100%' : '0'}
          visibility={searchActive ? 'initial' : 'hidden'}
          autoFocus
          ref={searchInputRef}
          width={'240px'}
          backgroundColor={'white'}
          onBlur={() => {
            if (localSearchText.length === 0) {
              setSearchActive(false);
            }
          }}
          placeholder="Search..."
          _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          defaultValue={localSearchText}
          onChange={(e) => setLocalSearchText(e.target.value)}
          isDisabled={!searchActive}
          _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
        />

        {localSearchText.length && (
          <InputRightElement width="55px" height={'100%'}>
            <VStack backgroundColor={'white'}>
              {isSearching ? (
                <Spinner />
              ) : (
                <Button
                  height={'30px'}
                  width={'30px'}
                  onClick={() => {
                    setLocalSearchText('');
                    setFilterText('');
                    if (searchInputRef.current) searchInputRef.current.value = '';
                    setTimeout(() => searchInputRef.current?.focus(), 0);
                  }}
                >
                  <CloseIcon color={colors.text.mediumGray} boxSize={3} fontSize={'30px'} />
                </Button>
              )}
            </VStack>
          </InputRightElement>
        )}
      </InputGroup>
    </VStack>
  );
};

export default SearchInputBox;
