import Dropdown from '@/components/atoms/Dropdown';
import { Button, HStack, Input, InputGroup, InputRightElement, Spinner, VStack } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { debounce } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';

interface Props<TFilterOption> {
  filterOptions: readonly string[];
  filterOption: string | undefined;
  setFilterOption: Dispatch<SetStateAction<TFilterOption>>;
  filterText: string;
  setFilterText: Dispatch<SetStateAction<string>>;
  isLoadingSearch?: boolean;
}

const SearchWithDropdownFilter = <TFilterOption,>({
  filterOptions,
  filterOption,
  setFilterOption,
  filterText,
  setFilterText,
  isLoadingSearch,
}: Props<TFilterOption>) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const debouncedSetFilterText = debounce((value) => setFilterText(value), 500);

  return (
    <HStack gap={'15px'}>
      <Dropdown<TFilterOption>
        title="Select filter"
        selectedOption={filterOption}
        onOptionClick={(option) => setFilterOption(option as TFilterOption)}
        options={filterOptions as TFilterOption[]}
      />
      <InputGroup>
        <Input
          ref={searchInputRef}
          width={'300px'}
          backgroundColor={'white'}
          placeholder={
            filterOption === undefined
              ? 'Please select a filter'
              : `Search by ${filterOption !== undefined ? filterOption : '...'}`
          }
          _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          defaultValue={filterText}
          onChange={(e) => debouncedSetFilterText(e.target.value)}
          isDisabled={filterOption === undefined}
          _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
        />

        <InputRightElement width="55px" height={'100%'}>
          <VStack backgroundColor={'white'}>
            {isLoadingSearch ? (
              <Spinner />
            ) : (
              <Button
                height={'30px'}
                onClick={() => {
                  setFilterText('');
                  if (searchInputRef.current) searchInputRef.current.value = '';
                }}
              >
                <CloseIcon />
              </Button>
            )}
          </VStack>
        </InputRightElement>
      </InputGroup>
    </HStack>
  );
};

export default SearchWithDropdownFilter;
