import { svgIconPaths, svgIcons } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import LoadingPage from '@/components/templates/LoadingPage';
import {
  MAX_CONTAINER_WIDTH,
  TIMELINE_DATE_TEXT_DIMENSION,
  TIMELINE_ICON_DIMENSION,
  TIMELINE_ITEMS_GAP,
} from '@/constants/dimensions';
import { useMemberCareMemberProfile, useMemberCareMemberTimeline } from '@/hooks/member-care/memberCare';
import colors from '@/theme/colors';
import { TMemberCareMemberTimelineItem } from '@/types/MemberCare.types';
import getTimelineIconFromType from '@/utils/getTimelineIconFromType';
import { getAgeFromBirthDay } from '@/utils/stringUtils';
import { Box, Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { formatDate } from 'date-fns';

const IMAGE_SIZE_PX = '80px';

const MEMBER_DOCUMENTS_MOCK: TMemberCareMemberTimelineItem[] = [
  {
    id: '6011f311-2c4d-4e10-88a8-2b6bf16054ab',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-12-06T21:10:45.868Z',
    scores: {
      mental: 8.4,
      spiritual: 2.3,
      vocational: 1,
      fun: 5.3999999999999995,
      purpose: 3.5999999999999996,
      social: 3.6999999999999997,
      physical: 9.7,
    },
  },
  {
    id: '366ed304-3cd1-4a35-9bbf-2cb7609297c3',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-10-17T19:38:08.040Z',
    scores: {
      mental: 8,
      spiritual: 8.3,
      vocational: 8.3,
      fun: 1,
      purpose: 1.3,
      social: 8.7,
      physical: 2,
    },
  },
  {
    id: 'ac9182bb-a343-479b-965a-480804f87668',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-12-07T09:48:47.690Z',
    scores: {
      mental: 8.7,
      spiritual: 5.699999999999999,
      vocational: 6.699999999999999,
      fun: 3.6999999999999997,
      purpose: 4.7,
      social: 7.699999999999999,
      physical: 9.7,
    },
  },
  {
    id: '0281c85c-b4c3-4f7f-8e29-cbea0dab051f',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-10-17T22:24:45.416Z',
    scores: {
      mental: 3,
      spiritual: 9,
      vocational: 7,
      fun: 1,
      purpose: 2,
      social: 7,
      physical: 1,
    },
  },
  {
    id: 'b6654aa7-d619-4e86-872d-e5d505cd59a1',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-10-16T22:10:04.379Z',
    scores: {
      mental: 1,
      spiritual: 6,
      vocational: 4,
      fun: 10,
      purpose: 8,
      social: 3,
      physical: 0,
    },
  },
  {
    id: '835e33a8-1b0e-43e5-8570-a9c2b3651a28',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-10-17T22:26:51.184Z',
    scores: {
      mental: 7,
      spiritual: 10,
      vocational: 10,
      fun: 10,
      purpose: 9,
      social: 9,
      physical: 1,
    },
  },
  {
    id: '9527b6ca-75e9-4a2d-8609-99ad8d292e3f',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-10-21T09:27:09.266Z',
    scores: {
      mental: 8.7,
      spiritual: 8,
      vocational: 8,
      fun: 6.3,
      purpose: 8,
      social: 6.2,
      physical: 8.4,
    },
  },
  {
    id: 'fee9c670-7aeb-49fb-be88-5b54889f5179',
    title: 'Untitled Document',
    submitted_at: '2024-09-18T15:45:04.307Z',
    scores: {
      mental: 8,
      spiritual: 1.5,
      vocational: 5,
      fun: 7,
      purpose: 1,
      social: 4,
      physical: 9.5,
    },
  },
  {
    id: '09ede6c2-81d0-4ae2-943a-a2e0dc1e4e6b',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-10-17T15:24:01.960Z',
    scores: {
      mental: 2,
      spiritual: 6,
      vocational: 5,
      fun: 10,
      purpose: 9,
      social: 4,
      physical: 1,
    },
  },
  {
    id: 'd5dfd3ef-40ed-43b8-8243-2cd7aa1ebcaf',
    title: 'MyOmnia is a wholeness platform that will customize your experience to your needs and goals.',
    submitted_at: '2024-12-07T10:55:05.681Z',
    scores: {
      mental: 1,
      spiritual: 1,
      vocational: 1,
      fun: 1,
      purpose: 1,
      social: 1,
      physical: 1,
    },
  },
];

const MemberProfileView = () => {
  const { memberCareMemberId } = Route.useParams();

  console.log('memberCareMemberId', memberCareMemberId);

  const { data, isLoading } = useMemberCareMemberProfile(memberCareMemberId);
  const { data: memberTimelineItems, isLoading: isLoadingMemberDocuments } =
    useMemberCareMemberTimeline(memberCareMemberId);
  console.log('data', data);

  if (isLoading || isLoadingMemberDocuments || !data || !memberTimelineItems) return <LoadingPage />;

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH}>
        {/* Actual page content */}
        <VStack width={'100%'} marginBottom={'50px'}>
          <HStack justify={'space-between'} width={'100%'} marginBottom={'50px'}>
            {/* User photo and info */}
            <HStack gap={'20px'}>
              <Image
                src={data.profilePicture || svgIcons.genericUser}
                width={IMAGE_SIZE_PX}
                height={IMAGE_SIZE_PX}
                alt="member-profile-photo"
                borderRadius={'50%'}
                objectFit={'cover'}
              />
              <VStack gap={'2px'} alignItems={'flex-start'}>
                <Text variant="loraSmallTitle" fontWeight={'500'}>
                  {data.firstName} {data.lastName}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  {data.orgName || data.organizationName}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  {data.dateOfBirth &&
                    getAgeFromBirthDay(data.dateOfBirth) > 0 &&
                    `${getAgeFromBirthDay(data.dateOfBirth)} years old`}{' '}
                  {data.sex}
                </Text>
              </VStack>
            </HStack>

            {/* Chat and Call buttons */}
            <HStack gap={8}>
              <CustomButton
                onClick={() => {}} // TODO: SCREENER NAV  ->  navigate({ to: '/screener/$screenerId', params: { screenerId: 'wholeness-screener' } })
                isTransparent
                label="Chat"
                labelColor={colors.text.darkBlue}
                iconBeforeLabelSvgPath={svgIconPaths.message}
                iconBeforeLabelSvgColor={colors.text.darkBlue}
                hoverBackgroundColor="extra.white"
                disabled
              />
              <CustomButton
                backgroundColor="primary.500"
                onClick={() => alert('Requesting . . .')}
                label="Call"
                labelColor={colors.text.darkBlue}
                iconBeforeLabelSvgPath={svgIconPaths.phone}
                iconBeforeLabelSvgColor={colors.text.darkBlue}
                disabled
              />
            </HStack>
          </HStack>

          <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'}>
            {data.firstName}'s Wellness Report
          </Text>
          <WellnessStateCircle wellnessData={data.healthDomainScores} userAvatar={data.profilePicture} />

          <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'}>
            {data.firstName}'s Wellness Timeline
          </Text>
          <VStack justifyContent={'flex-start'} gap={'40px'}>
            {/* TODO: this needs to be updated to differentiate between timeline type (e.g. call, email, screener-taken...)  { once we load the actual TIMELINE items, not just the documents }*/}
            {memberTimelineItems.map((timelineItem, i) => {
              const totalScore = Object.values(timelineItem.scores)
                .reduce((acc, curr) => (acc += curr), 0)
                .toFixed();
              return (
                <HStack position={'relative'} width={'100%'} gap={`${TIMELINE_ITEMS_GAP}px`}>
                  <Text
                    variant={'urbanistSemiBold'}
                    color={'text.mediumBlue'}
                    width={`${TIMELINE_DATE_TEXT_DIMENSION}px`}
                  >
                    {formatDate(new Date(timelineItem.submitted_at), 'dd. MMM')}
                  </Text>
                  <Center
                    width={TIMELINE_ICON_DIMENSION}
                    height={TIMELINE_ICON_DIMENSION}
                    backgroundColor={'extra.white'}
                    borderRadius={'full'}
                    boxShadow={'0px 3.18px 15.88px 0px #00417933'}
                    zIndex={2}
                  >
                    {/* TODO: once proper GET Timeline EP is done, remove `?? 'screener'` because we will always have `itemType` */}
                    {getTimelineIconFromType(timelineItem.itemType ?? 'screener')}
                  </Center>
                  {/* <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'}>{memberDocument.title}</Text> // TODO: Use this once we get ACTUAL Timeline items, not just documents */}
                  <HStack gap={'4px'}>
                    <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'}>
                      Took the screener.
                    </Text>
                    <Text variant={'urbanistSemiBold'} color={'primary.500'}>
                      Wellness Score {totalScore}
                    </Text>
                  </HStack>
                  {i < MEMBER_DOCUMENTS_MOCK.length - 1 && (
                    <Box
                      position={'absolute'}
                      top={TIMELINE_ICON_DIMENSION}
                      left={`${TIMELINE_DATE_TEXT_DIMENSION + TIMELINE_ITEMS_GAP + TIMELINE_ICON_DIMENSION / 2 - 1}px`}
                      height={'40px'}
                      width={'2px'}
                      backgroundColor={'#CBDEED'}
                      zIndex={2}
                    ></Box>
                  )}
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/$memberCareMemberId')({
  component: MemberProfileView,
});
