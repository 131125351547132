import CustomButton from '@/components/atoms/CustomButton';
import colors from '@/theme/colors';
import { HStack } from '@chakra-ui/react';
import React from 'react';

export interface ConfirmCancelButtonProps {
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonText: string;
  confirmButtonColor?: string;
  confirmLoading?: boolean;
}

const ConfirmCancelButtons = ({
  confirmButtonText,
  confirmButtonColor,
  onClose,
  onConfirm,
  confirmLoading,
}: ConfirmCancelButtonProps) => {
  return (
    <HStack justifyContent={'center'} width={'100%'} marginBottom={'20px'} gap={'30px'}>
      <CustomButton
        isTransparent
        labelColor="text.mediumBlue"
        labelHoverColor={colors.extra.black}
        borderHoverColor="transparent"
        label="Cancel"
        onClick={onClose}
        labelStyle={{ fontSize: '16px' }}
        style={{ marginTop: '20px' }}
        height={'60px'}
        width={'110px'}
      />
      <CustomButton
        label={confirmButtonText}
        labelColor="extra.white"
        labelHoverColor="extra.white"
        backgroundColor={confirmButtonColor}
        labelStyle={{ fontSize: '16px' }}
        onClick={onConfirm}
        style={{ marginTop: '20px', minWidth: '110px' }}
        height={'60px'}
        isLoading={confirmLoading}
      />
    </HStack>
  );
};

export default ConfirmCancelButtons;
