import {
  createAssessmentPost,
  deleteAssessmentById,
  getAllAssessments,
  getAssessmentById,
  updateAssessmentById,
} from '@/services/api/requests/assessment';
import { usePagination } from '../usePagination';
import { TApiAssessment, TCreateAssessment } from '@/types/Assessment.types';
import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { TCustomAxiosError } from '@/services/api';
import useToast from '../useToast';
import { useDocumentEditorStore } from '@/store/documentEditor';
import queryKeys from '@/constants/queryKeys';

const useAssessments = () => {
  return usePagination<TApiAssessment[]>({
    query: getAllAssessments,
    queryKey: queryKeys.assessment.allAssessments,
  });
};

const useCreateAssessment = (
  onSuccess?: (assessment: TApiAssessment) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, TCreateAssessment, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (vars: TCreateAssessment) => {
      const response = await createAssessmentPost(vars);
      return response;
    },
    onSuccess: (assessment) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Assessment successfully created.',
      });

      onSuccess?.(assessment);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Creating assessment error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useDeleteAssessment = (
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, { assessmentId: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ assessmentId }: { assessmentId: string }) => {
      const response = await deleteAssessmentById({
        id: assessmentId,
      });
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Assessment successfully deleted.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Deleting assessment error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useUpdateAssessment = (
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, { assessmentId: string }, unknown>;
} => {
  const toast = useToast();

  const { document } = useDocumentEditorStore();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ assessmentId }: { assessmentId: string }) => {
      if (document === undefined) return;

      const response = await updateAssessmentById({
        id: assessmentId,
        data: document,
      });
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Assessment successfully updated.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Updating assessment error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useAssessmentById = (
  assessmentId: string,
): {
  isLoading: boolean;
  isSuccess: boolean;
  data: TApiAssessment | undefined;
  isError: boolean;
  error: Error | null;
} => {
  const { isLoading, isSuccess, data, isError, error } = useQuery({
    queryKey: [queryKeys.assessment.allAssessments, assessmentId],
    queryFn: async () => {
      const response = await getAssessmentById({
        id: assessmentId,
      });
      return response;
    },
  });

  return { isLoading, isSuccess, data, isError, error };
};

export { useAssessments, useCreateAssessment, useDeleteAssessment, useUpdateAssessment, useAssessmentById };
