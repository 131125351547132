import { CENTERED_FORM_WIDTH, HEADER_HEIGHT } from '@/constants/dimensions';
import { Center, Flex, Stack, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = {
  title: string;
  description: string;
  contentMaxWidth?: string;
  hideButtons?: boolean;
  centeredLogo?: boolean;
};

const BlueTopPage = ({
  title,
  description,
  children,
  contentMaxWidth = '820px',
  centeredLogo = false,
}: PropsWithChildren<Props>) => {
  return (
    <Flex width={'100%'} direction={'column'} alignItems={'center'}>
      <Flex width={'100%'} direction={'column'} backgroundColor={'primary.500'} alignItems={'center'}>
        <Center minHeight={'300px'} marginTop={`${HEADER_HEIGHT / 3}px`}>
          <Flex maxWidth={{ md: CENTERED_FORM_WIDTH }}>
            <Stack paddingTop={centeredLogo ? '30px' : 0}>
              <Text textAlign={'center'} variant="loraTitle" color="white" fontSize={'26px'}>
                {title}
              </Text>
              <Text
                textAlign={'center'}
                color={'text.veryLightBlue'}
                opacity={0.7}
                lineHeight={'20px'}
                fontWeight={'600'}
                paddingTop={centeredLogo ? '20px' : 0}
              >
                {description}
              </Text>
            </Stack>
          </Flex>
        </Center>
      </Flex>
      <Flex width={'100%'} direction={'column'} maxWidth={{ md: contentMaxWidth }} marginTop={'80px'}>
        <Center>{children}</Center>
      </Flex>
    </Flex>
  );
};

export default BlueTopPage;
