import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { TApiAssessment } from '@/types/Assessment.types';
import { useDeleteAssessment } from '@/hooks/assessment/assessment';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';
import queryKeys from '@/constants/queryKeys';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  assessment?: TApiAssessment;
};

const DeleteAssessmentModal = ({ isOpen, onClose, assessment }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useDeleteAssessment(() => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.assessment.allAssessments] });
    onClose?.();
  });

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} height={'22vh'} width="40%">
      <VStack>
        <Text variant={'loraSmallTitle'} color={'primary.blueGray'} marginTop={'-10px'}>
          Are you sure?
        </Text>
        <Text fontWeight={'500'} fontSize={'20px'} color={'primary.darkBlue'} marginY={'20px'}>
          This will delete {assessment?.title}
        </Text>
        <CustomButton
          label="Yes, Delete"
          onClick={() => {
            if (assessment !== undefined) {
              mutate({ assessmentId: assessment._id });
            }
          }}
          isLoading={isPending}
          backgroundColor="extra.red"
        />
      </VStack>
    </ModalContainer>
  );
};

export default DeleteAssessmentModal;
