import iconsPng from '@/assets/img/png/icons';
import CustomButton from '@/components/atoms/CustomButton';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import appMessages from '@/messages';
import onboardingMessages from '@/messages/onboarding';
import {
  Button,
  FormControl,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface Props {
  onSubmit: (password: string) => void;
  isLoading: boolean;
}

const NewPasswordForm = ({ onSubmit, isLoading }: Props) => {
  const methods = useForm<{ password: string; passwordConfirmation: string }>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        password: yup
          .string()
          .required('Password is required')
          .min(8, 'Password must be at least 8 chars long')
          .matches(/^(?=.*?[A-Z]).{8,}$/, 'Please include a capital letter')
          .matches(/^(?=.*?[a-z]).{8,}$/, 'Please include a lowercase letter')
          .matches(/(?=.*?[0-9#?!@$%^&*-]).{8,}$/, `Please include ${onboardingMessages.passwordNumberOrSpecialChar}`),
        passwordConfirmation: yup
          .string()
          .required('Password Confirmation is required')
          .oneOf([yup.ref('password'), ''], 'Passwords must match'),
      }),
    ),
  });

  const { handleSubmit, getValues, register, formState, trigger } = methods;
  const { errors, isValid } = formState;

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const onSubmitLocal = () => {
    const password = getValues().password;
    onSubmit(password);
  };

  return (
    <VStack>
      <VStack alignItems={'start'} width={FORM_FIELD_WIDTH} gap={'3px'}>
        <Text variant={'urbanistSemiBold'} marginBottom={'10px'}>
          {onboardingMessages.passwordMustContain}
        </Text>
        <HStack>
          <Text variant={'urbanistMaximumBold'}>{onboardingMessages.listItemDot}</Text>
          <Text variant={'urbanistMediumLarge'}>{onboardingMessages.password8chars}</Text>
        </HStack>
        <HStack>
          <Text variant={'urbanistMaximumBold'}>{onboardingMessages.listItemDot}</Text>
          <Text variant={'urbanistMediumLarge'}>{onboardingMessages.passwordLowercase}</Text>
        </HStack>
        <HStack>
          <Text variant={'urbanistMaximumBold'}>{onboardingMessages.listItemDot}</Text>
          <Text variant={'urbanistMediumLarge'}>{onboardingMessages.passwordUppercase}</Text>
        </HStack>
        <HStack>
          <Text variant={'urbanistMaximumBold'}>{onboardingMessages.listItemDot}</Text>
          <Text variant={'urbanistMediumLarge'}>{onboardingMessages.passwordNumberOrSpecialChar}</Text>
        </HStack>
      </VStack>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitLocal)}>
          <VStack marginTop={'40px'} gap={'20px'}>
            <FormControl isInvalid={!!errors.password}>
              <InputGroup>
                <Input
                  {...register('password', {
                    onChange: () => {
                      trigger('passwordConfirmation');
                    },
                  })}
                  className="custom-input"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={appMessages.password}
                />
                <InputRightElement width="4rem" height={'100%'}>
                  <Button onClick={() => setShowPassword(!showPassword)}>
                    <Image
                      src={showPassword ? iconsPng.eyeClosed : iconsPng.eyeOpened}
                      width={25}
                      height={25}
                      alt={showPassword ? 'eyeOpened' : 'eyeClosed'}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text color={'extra.red'}>{errors.password?.message ?? ''}</Text>
            </FormControl>

            <FormControl isInvalid={!isValid && !!errors.passwordConfirmation}>
              <InputGroup>
                <Input
                  {...register('passwordConfirmation')}
                  className="custom-input"
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  placeholder={appMessages.repeatPassword}
                />
                <InputRightElement width="4rem" height={'100%'}>
                  <Button onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
                    <Image
                      src={showPasswordConfirmation ? iconsPng.eyeClosed : iconsPng.eyeOpened}
                      width={25}
                      height={25}
                      alt={showPasswordConfirmation ? 'eyeOpened' : 'eyeClosed'}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
              {!isValid && <Text color={'extra.red'}>{errors.passwordConfirmation?.message ?? ''}</Text>}
            </FormControl>

            <CustomButton
              label="Confirm"
              backgroundColor="secondary.500"
              width={'300px'}
              disabled={!isValid}
              isLoading={isLoading}
              buttonType={'submit'}
              style={{ marginTop: '50px' }}
            />
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
};

export default NewPasswordForm;
