import { THealthDomainScores } from '@/types/Health.types';

export const HOUR_IN_MILISECONDS = 3600000;
export const MIN_CHARS_TO_DO_SEARCH = 3;

export const WELLNESS_STATE_LIMIT_DANGER = 33;
export const WELLNESS_STATE_LIMIT_OK = 66;

export const EMPTY_WELLNESS_DOMAINS: THealthDomainScores = {
  mental: 0,
  physical: 0,
  social: 0,
  spiritual: 0,
  vocational: 0,
  purpose: 0,
  fun: 0,
};

export const WELLNESS_DOMAIN_QUESTION_TYPES = ['wellness_level', 'readiness_level'] as const;

export const NO_OF_WELLNESS_DOMAINS = Object.values(EMPTY_WELLNESS_DOMAINS).length;

export const WELLNESS_DATA_MULTIPLIER = 10;
