import queryKeys from '@/constants/queryKeys';
import { api } from '@/services/api';
import { queryOptions } from '@tanstack/react-query';

type TAverageScoresData = {
  mental: number;
  spiritual: number;
  vocational: number;
  fun: number;
  purpose: number;
  social: number;
  physical: number;
};

export const getOrganizations = () =>
  queryOptions({
    queryKey: [queryKeys.organization.allOrganizations],
    queryFn: async (): Promise<TAverageScoresData> => {
      const res = await api.get('/v1/organization');
      // TODO: If you think that be can return status 200 but "success": false, you can throw an error here
      return res.data.data;
    },
  });
