import CircleFillOutWithText from '@/components/molecules/WellnessStateCircle/CircleFillOutWithText';
import DateInput from '@/components/atoms/DateInput';
import Dropdown from '@/components/atoms/Dropdown';
import CustomTable from '@/components/molecules/CustomTable';
import FilterSelectPopover from '@/components/molecules/FilterSelectPopover';
import SearchInputBox from '@/components/molecules/SearchInputBox';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import {
  MEMBER_CARE_SCORE_FILTER_FE_BE,
  MEMBER_CARE_SCORE_FILTER_LABELS,
  MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL,
} from '@/constants/stringVars';
import { useMemberCareMembers } from '@/hooks/member-care/memberCare';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';
import { TMemberCareScoreFilterLabelFE, TMemberCareMember } from '@/types/MemberCare.types';
import { getColorFromPercentage } from '@/utils/getColorFromPercentage';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { isBefore } from 'date-fns';
import { useMemo, useState } from 'react';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';

const COLUMNS = ['Member Name', 'Organization', 'Last Activity', 'Score'] as const;
export type TMemberCareMembersSortingColumn = (typeof COLUMNS)[number];

const MemberCareMembers = () => {
  const { sortingColumn, sortingDirection, updateSortingColumn } = useTableColumnSort<TMemberCareMembersSortingColumn>({
    defaultSortingColumn: 'Score',
  });

  const [localLastActivityFromDate, setLocalLastActivityFromDate] = useState('');
  const [localLastActivityToDate, setLocalLastActivityToDate] = useState('');
  const [localScoreFilter, setLocalScoreFilter] = useState<TMemberCareScoreFilterLabelFE>('All Scores');

  const [committedLastActivityFromDate, setCommittedLastActivityFromDate] = useState('');
  const [committedLastActivityToDate, setCommittedLastActivityToDate] = useState('');
  const [committedScoreFilter, setCommittedScoreFilter] = useState<TMemberCareScoreFilterLabelFE>('All Scores');

  const filterIsActive = Boolean(
    committedLastActivityFromDate || committedLastActivityToDate || committedScoreFilter !== 'All Scores',
  );

  const [filterText, setFilterText] = useState('');

  const {
    data: members,
    pagination,
    isLoading,
    isRefetching,
  } = useMemberCareMembers({
    sortingColumn,
    sortingDirection,
    fromDate: committedLastActivityFromDate,
    toDate: committedLastActivityToDate,
    searchText: filterText,
    scoreFilter: committedScoreFilter,
  });

  const membersDataWithId = members ? members.map((member) => ({ ...member, id: member.user_id })) : [];

  const isSearching = useMemo(
    () => filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterText, filterText, isRefetching],
  );

  const tableListItems = useMemo(
    () =>
      membersDataWithId.map((memberCareMember: TMemberCareMember) => {
        let lastActivityLabel = 'No Activity';
        if (memberCareMember.assessmentSubmittedAt) {
          const [year, month, date] = memberCareMember.assessmentSubmittedAt.split('T')[0].split('-');
          lastActivityLabel = `${month}/${date}/${year}`;
        }

        return {
          id: memberCareMember.user_id,
          cells: [
            `${memberCareMember.firstName}-${memberCareMember.lastName}`,
            memberCareMember.orgName ?? memberCareMember.organizationName ?? '',
            lastActivityLabel,
            !memberCareMember?.totalScore ? (
              <Text variant={'urbanistSmallBold'} color="text.mediumGray">
                NO SCORE
              </Text>
            ) : (
              <HStack gap={'14px'} marginLeft={'5px'}>
                <CircleFillOutWithText
                  circleDiameter={25}
                  svgId={`${memberCareMember.firstName}-${memberCareMember.lastName}`}
                  thickness={4}
                  percentageFilled={memberCareMember.totalScore}
                  circleColor={getColorFromPercentage(memberCareMember.totalScore)}
                  showPercentageText={false}
                  boxShadow={'0px 3px 6px 0px #0E006224'}
                />
                <Text variant={'urbanistSemiBold'}>{memberCareMember.totalScore.toFixed()}</Text>
              </HStack>
            ),
          ],
        };
      }),
    [membersDataWithId],
  );

  // Updates all the 'committed' states which are sent to the data-fetching hook `useMemberCareMembers`
  const submitLocalForm = () => {
    setCommittedLastActivityFromDate(localLastActivityFromDate);
    setCommittedLastActivityToDate(localLastActivityToDate);
    setCommittedScoreFilter(localScoreFilter);
  };

  const submitButtonDisabled =
    localLastActivityFromDate === committedLastActivityFromDate &&
    localLastActivityToDate === committedLastActivityToDate &&
    localScoreFilter === committedScoreFilter;

  const scoreFilterNumberRangeDisplay = useMemo(() => {
    if (localScoreFilter !== 'All Scores') {
      const scoreDataRange = MEMBER_CARE_SCORE_FILTER_FE_BE[MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL[localScoreFilter]];

      if (scoreDataRange) {
        return (
          <Text variant={'urbanistBold'}>
            {scoreDataRange.fromScore * 10} to {scoreDataRange.toScore * 10}
          </Text>
        );
      }
    }
    return '';
  }, [localScoreFilter]);

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH}>
        <HStack justifyContent={'space-between'} width={'100%'}>
          <Text variant="loraTitle">My Members</Text>
          <HStack>
            <FilterSelectPopover
              onFilterSubmit={submitLocalForm}
              submitButtonDisabled={submitButtonDisabled}
              isFilterActive={filterIsActive}
              popoverContent={
                <VStack marginBottom={'24px'} alignItems={'start'}>
                  <Text variant={'urbanistBold'} marginY={'10px'}>
                    Last Activity
                  </Text>
                  <HStack gap={'1rem'}>
                    <DateInput
                      label="From"
                      value={localLastActivityFromDate}
                      updateValue={(value) => {
                        setLocalLastActivityFromDate(value);
                        if (
                          localLastActivityToDate.length &&
                          isBefore(new Date(localLastActivityToDate), new Date(value))
                        ) {
                          setLocalLastActivityToDate(value);
                        }
                      }}
                    />
                    <DateInput
                      label="To"
                      value={localLastActivityToDate}
                      minValue={localLastActivityFromDate}
                      updateValue={(value) => setLocalLastActivityToDate(value)}
                    />
                  </HStack>
                  <HStack width={'100%'} justifyContent={'space-between'} marginY={'10px'}>
                    <Text variant={'urbanistBold'}>Score</Text>
                    {scoreFilterNumberRangeDisplay}
                  </HStack>
                  <Dropdown
                    options={MEMBER_CARE_SCORE_FILTER_LABELS}
                    title={committedScoreFilter}
                    selectedOption={localScoreFilter}
                    defaultOption={committedScoreFilter === 'All Scores' ? 'All Scores' : ''}
                    popoverWidth="320px"
                    width="100%"
                    height="54px"
                    onOptionClick={(option) => setLocalScoreFilter(option)}
                  />
                </VStack>
              }
            />

            <SearchInputBox setFilterText={setFilterText} isSearching={isSearching} />
          </HStack>
        </HStack>

        <CustomTable<TMemberCareMember[], TMemberCareMembersSortingColumn>
          isLoading={isLoading}
          dataWithId={membersDataWithId}
          isRefetching={isRefetching}
          pagination={pagination}
          tableListItems={tableListItems}
          isSearching={false}
          noItemsMessage="No users"
          columns={COLUMNS}
          sortingColumn={sortingColumn}
          updateSortingColumn={updateSortingColumn}
          sortingDirection={sortingDirection}
          tableWidthPercentage="100%"
          hasRowSelect
        />
        </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/')({
  component: MemberCareMembers,
});
