import SidebarContentWrapper from '@/modules/sidebar/SidebarContentWrapper';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

const AdminLayout = () => {
  return <SidebarContentWrapper>
    <Outlet />
  </SidebarContentWrapper>

}

export const Route = createFileRoute('/_authenticated/_admin')({
  component: AdminLayout,
  beforeLoad: async ({ context: { auth } }) => {
    const isSuperAdmin = auth?.isSuperAdmin;

    if (!isSuperAdmin) {
      throw redirect({
        to: '',
      });
    }
  },
});
