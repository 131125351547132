import { svgIcons } from '@/assets/svg/icons';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';

const nowDate = new Date().toISOString().split('T')[0];

const oneHundredYearsAgoTimeStamp = new Date().setFullYear(new Date().getFullYear() - 100);
const oneHundredYearsAgo = new Date(oneHundredYearsAgoTimeStamp).toISOString().split('T')[0];

interface Props {
  label: string;
  value: string;
  updateValue: (_: string) => void;
  minValue?: string;
}

const DateInput = ({ label, value, updateValue, minValue = oneHundredYearsAgo }: Props) => {
  const dateInputRef = useRef<HTMLInputElement>(null);

  const showDatePicker = () => {
    if (dateInputRef.current) {
      try {
        dateInputRef.current.showPicker();
      } catch (e) {} // catch the 'no-user-gesture' error
    }
  };

  const valueDisplay = useMemo(() => {
    const splitDate = value.split('-');
    return value ? `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}` : label;
  }, [value, label]);

  return (
    <HStack
      pos={'relative'}
      cursor={'pointer'}
      onClick={showDatePicker}
      border={`1px solid ${value ? '#004179' : '#00417926'}`}
      borderRadius={'8px'}
    >
      {/* Absolute cover over the date input; allows for the calendar pop-out to be positioned just under the input */}
      <HStack
        pos={'absolute'}
        zIndex={2}
        top={value ? '12px' : '7px'}
        left={'18px'}
        width={'77%'}
        backgroundColor={'white'}
        justifyContent={'space-between'}
        height={'40px'}
        cursor={'pointer'}
        onClick={() => dateInputRef.current && dateInputRef.current.showPicker()}
      >
        <VStack alignItems={'flex-start'} gap={1}>
          {value && (
            <Text variant={'urbanistExtraBoldSmall'} color={'text.mediumGray'}>
              {label.toUpperCase()}
            </Text>
          )}
          <Text
            variant={'urbanistBold'}
            marginTop={value ? '0' : '6px'}
            color={value ? 'text.mediumBlue' : 'text.mediumGray'}
          >
            {valueDisplay}
          </Text>
        </VStack>
        <Image
          alt="date-input-calendar"
          src={svgIcons.calendar}
          height={'16px'}
          width={'16px'}
          style={{ marginTop: value ? '-5px' : '5px' }}
        />
      </HStack>
      <input
        type="date"
        ref={dateInputRef}
        style={{ cursor: 'pointer', color: 'white', visibility: 'hidden' }}
        className="custom-input"
        onChange={(e) => {
          const newFromDate = e.target.value;
          updateValue(newFromDate);
        }}
        max={nowDate}
        min={minValue}
      />
    </HStack>
  );
};

export default DateInput;
