import {
  TMemberCare,
  TMemberCareFilterOption,
  TMemberCareInvite,
  TMemberCareOnboarding,
  TMemberCareOrgAssignment,
  TMemberCareMember,
} from '@/types/MemberCare.types';
import { api, TCustomAxiosPaginationResponse, TCustomAxiosResponse } from '..';
import { getBeUserFieldNameFromFilterOption } from '@/utils/stringUtils';
import { ITEMS_PER_PAGE } from '@/theme/components/table';
import { MIN_CHARS_TO_DO_SEARCH, WELLNESS_DATA_MULTIPLIER } from '@/constants/numberVars';
import {
  MEMBER_CARE_SCORE_FILTER_FE_BE,
  MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL,
  SexBeFe,
  SexFeBe,
} from '@/constants/stringVars';
import { TOrganization } from '@/types/Organization.types';
import { TFilterParams } from '@/types';
import { THealthDomainScores } from '@/types/Health.types';
import { TMemberCaresSortingColumn } from '@/routes/_authenticated/_admin/member-care';
import { TMemberCareMembersSortingColumn } from '@/routes/_authenticated/_member-care/member-care/members';

const rootPath = '/v1/membercare';

const endpoints = {
  memberCareUsers: `${rootPath}/users`,
  memberCareOrganizations: `${rootPath}/organizations`,
  memberCareUpdateProfile: `${rootPath}/profile`,
  memberCareAssign: `${rootPath}/assign`,
  memberCareUnassign: `${rootPath}/unassign`,
  memberCareInvite: `${rootPath}/invite`,
  memberCareMembers: `${rootPath}/my-members/scores`,
  organizationMemberCareSpecialists: `${rootPath}/organization`,
};

export const getAllMemberCareSpecialists = async (
  page: number,
  filterOption: TMemberCareFilterOption,
  filterText: string,
  sortingColumn?: string,
  sortingDirection?: string,
): Promise<TCustomAxiosPaginationResponse<TMemberCare[]>> => {
  const filterTypeBE = filterOption ? getBeUserFieldNameFromFilterOption(filterOption) : '';

  const params: any = {
    limit: ITEMS_PER_PAGE,
    page,
  };

  if (filterText && filterText?.length >= MIN_CHARS_TO_DO_SEARCH) {
    params[filterTypeBE] = filterText;
  }

  let sortBy = '';
  switch (sortingColumn as TMemberCaresSortingColumn) {
    case 'Email':
      sortBy = 'email';
      break;
    case 'Invitation Status':
      sortBy = 'invitation_status';
      break;
    case 'Last Sign In':
    default:
      sortBy = 'last_login';
      break;
  }

  params['sort_by'] = sortBy;
  params['order'] = sortingDirection;

  const { data } = await api.get(endpoints.memberCareUsers, {
    params,
  });

  return data;
};

export const getMemberCareSpecialistById = async (memberCareId: string): Promise<TCustomAxiosResponse<TMemberCare>> => {
  const { data: response } = await api.get<TCustomAxiosResponse<TMemberCare>>(
    `${endpoints.memberCareUsers}/${memberCareId}`,
  );

  const feData = { ...response.data, sex: SexBeFe[response.data.sex as keyof typeof SexBeFe] };

  return { ...response, data: feData };
};

export const inviteMemberCareToPlatformPost = async (memberCareToInvite: TMemberCareInvite): Promise<string> => {
  const { data } = await api.post(endpoints.memberCareInvite, memberCareToInvite);

  const result = data.data;

  return result;
};

export const assignOrgToMemberCarePost = async ({ memberCareId, orgId }: TMemberCareOrgAssignment): Promise<string> => {
  const { data } = await api.post(`${endpoints.memberCareUsers}/${memberCareId}/assign-organizations`, {
    organization_ids: [orgId],
  });

  return data.data;
};

export const unassignOrgFromMemberCareDelete = async ({
  memberCareId,
  orgId,
}: TMemberCareOrgAssignment): Promise<string> => {
  const { data } = await api.delete(`${endpoints.memberCareUsers}/${memberCareId}/unassign-organizations`, {
    data: {
      organization_ids: [orgId],
    },
  });

  return data.data;
};

export const updateMemberCarePut = async (memberCare: TMemberCareOnboarding, profilePhoto?: File): Promise<string> => {
  const sexBe = SexFeBe[memberCare.sex as keyof typeof SexFeBe];

  const formData = new FormData();
  formData.append('firstName', memberCare.firstName);
  formData.append('lastName', memberCare.lastName);
  formData.append('sex', sexBe);
  formData.append('phone', memberCare.phone);

  if (profilePhoto) {
    formData.append('profilePicture', new File([profilePhoto], profilePhoto.name));
  }
  if (memberCare.bio) {
    formData.append('bio', memberCare.bio);
  }
  if (memberCare.calendly_link) {
    formData.append('calendly_link', memberCare.calendly_link);
  }

  const { data } = await api.put(endpoints.memberCareUpdateProfile, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  const resultData = data.data;

  return resultData;
};

export const reactivateMemberCarePost = async (memberCareId: string): Promise<string> => {
  const { data } = await api.post(`${endpoints.memberCareUsers}/${memberCareId}/activate`);

  return data.data;
};

export const deactivateMemberCarePost = async (memberCareId: string): Promise<string> => {
  const { data } = await api.post(`${endpoints.memberCareUsers}/${memberCareId}/deactivate`);

  return data.data;
};

export const getMemberCareUnassignedOrganizations = async (
  page: number,
  memberCareId?: string,
): Promise<TCustomAxiosPaginationResponse<TOrganization[]>> => {
  const { data } = await api.get(`${endpoints.memberCareUsers}/member-care/${memberCareId}/unassigned-organizations`, {
    params: {
      limit: ITEMS_PER_PAGE,
      page,
    },
  });

  return data;
};

// export const getMemberCareMembersWithSearch = async (
//   page: number,
//   filterOption: TMemberCareMemberFilterOption,
//   filterText: string,
// ): Promise<TCustomAxiosPaginationResponse<TMemberCareMember[]>> => {
//   const filterTypeBE = filterOption ? getBeUserFieldNameFromFilterOption(filterOption) : '';

//   const params: any = {
//     limit: ITEMS_PER_PAGE,
//     page,
//   };

//   if (filterText && filterText?.length >= MIN_CHARS_TO_DO_SEARCH) {
//     params[filterTypeBE] = filterText;
//   }

//   const { data } = await api.get(endpoints.memberCareMembers, {
//     params,
//   });

//   return data;
// };

export const getMemberCareMembers = async (
  page: number,
  filterParams?: TFilterParams,
  sortingColumn?: string,
  sortingDirection?: string,
): Promise<TCustomAxiosPaginationResponse<TMemberCareMember[]>> => {

  let sort_by = '';
  switch (sortingColumn as TMemberCareMembersSortingColumn) {
    case 'Last Activity':
      sort_by = 'assessmentSubmittedAt'; // TODO: possibly change once `assessmentSubmittedAt` is no longer the ONLY activity a user can do
      break;

    case 'Member Name':
      sort_by = 'firstName';
      break;

    case 'Organization':
      sort_by = 'organizationName';
      break;

    case 'Score':
    default:
      sort_by = 'totalScore';
      break;
  }

  const params: any = {
    page,
    sort_by,
    order: sortingDirection,
    fromDate: filterParams?.fromDate,
    toDate: filterParams?.toDate,
  };

  if (filterParams?.searchText) {
    params.search = filterParams?.searchText;
  }

  if (filterParams?.scoreFilter) {
    const scoreFilterBe =
      MEMBER_CARE_SCORE_FILTER_FE_BE[MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL[filterParams.scoreFilter]];

    params['minScore'] = scoreFilterBe?.fromScore;
    params['maxScore'] = scoreFilterBe?.toScore;
  }

  if (filterParams?.searchText) {
    params.search = filterParams?.searchText;
  }

  const { data } = await api.get(endpoints.memberCareMembers, {
    params,
  });

  return data;
};

export const getMemberCareSpecialistsForOrganizations = async (
  page: number,
  org?: { orgId: string },
): Promise<TCustomAxiosPaginationResponse<TMemberCare[]>> => {
  const { data } = await api.get(`${endpoints.organizationMemberCareSpecialists}/${org?.orgId}/member-cares`, {
    params: { page },
  });

  return data;
};

export const getMemberCareMemberById = async (memberId: string): Promise<TCustomAxiosResponse<TMemberCareMember>> => {
  const { data: response } = await api.get<TCustomAxiosResponse<TMemberCareMember>>(
    `${endpoints.memberCareUsers}/${memberId}/details`,
  );

  const feData = { ...response.data };
  if (response.data?.sex) {
    feData.sex = SexBeFe[response.data?.sex as keyof typeof SexBeFe];
  }

  feData.totalScore = response.data.totalScore * WELLNESS_DATA_MULTIPLIER;

  const healthDomainScores = response.data.healthDomainScores;

  for (const key in healthDomainScores) {
    if (typeof healthDomainScores[key as keyof THealthDomainScores] == 'number') {
      healthDomainScores[key as keyof THealthDomainScores] *= WELLNESS_DATA_MULTIPLIER;
    }
  }

  feData.healthDomainScores = healthDomainScores;

  return { ...response, data: feData };
};
