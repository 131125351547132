import {
  Button,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';

interface Props<TOption> {
  title: string;
  options: readonly TOption[];
  selectedOption?: string;
  defaultOption?: string;
  onOptionClick: (option: TOption) => void;
  height?: string;
  width?: string;
  popoverWidth?: string;
}

const Dropdown = <TOption,>({
  title,
  options,
  selectedOption,
  defaultOption,
  onOptionClick,
  height = '40px',
  width = '',
  popoverWidth = '190px',
}: Props<TOption>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const noValueSelected = !selectedOption || (selectedOption && selectedOption === defaultOption);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={'bottom'}>
      <PopoverTrigger>
        <Button
          minWidth={'170px'}
          minHeight={'40px'}
          height={height}
          width={width}
          bg={isOpen ? colors.background.lightBlue : 'transparent'}
          cursor={'pointer'}
          paddingY={0}
          border={`1px solid ${noValueSelected ? '#E2E8F0' : '#004179'}`}
          backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
          _hover={{ backgroundColor: colors.background.lightBlue }}
        >
          <HStack height={'100%'} width={'100%'}>
            <VStack flexGrow={1} alignItems={'flex-start'}>
              <Text
                fontSize={'14px'}
                color={noValueSelected ? 'text.mediumGray' : 'text.darkBlue'}
                lineHeight={'20px'}
                fontWeight={700}
              >
                {noValueSelected ? title : selectedOption}
              </Text>
            </VStack>
            {isOpen ? (
              <ChevronUpIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            ) : (
              <ChevronDownIcon boxSize={'5'} color={noValueSelected ? colors.text.mediumGray : colors.text.darkBlue} />
            )}
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={popoverWidth}
        border={0}
        padding={'10px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        {options.map((option) => (
          <Flex
            key={option as string}
            cursor={'pointer'}
            paddingX={'10px'}
            paddingY={'5px'}
            _hover={{ fontWeight: 800 }}
            fontWeight={option === selectedOption ? 800 : 500}
            onClick={() => {
              onOptionClick(option);
              onClose();
            }}
          >
            {option as string}
          </Flex>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default Dropdown;
