import { createFileRoute } from '@tanstack/react-router';
import { Text, useDisclosure } from '@chakra-ui/react';
import { getOrganizations } from '@/utils/queryOptions';

import LoadingPage from '@/components/templates/LoadingPage';
import { useOrganizations } from '@/hooks/organization/organization';
import CreateOrganizationModal from '@/modules/organization/CreateOrganizationModal';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { useMemo } from 'react';
import CustomTable from '@/components/molecules/CustomTable';
import { TOrganization } from '@/types/Organization.types';

const COLUMNS: string[] = ['Name', 'Website', 'Representative Email', 'Members Count'];
type TSortingColumn = (typeof COLUMNS)[number];

const Organizations = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, data: organizationsResponse, pagination, isRefetching } = useOrganizations();

  const organizationsTableData = organizationsResponse?.map((organization) => ({
    ...organization,
    id: organization._id,
  }));

  const data = { nodes: organizationsTableData ?? [] };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item._id,
          cells: [
            item.name,
            item.website ?? '',
            item.representativeEmail ?? '',
            <Text marginLeft={'40px'} fontWeight={'bold'}>
              {item.members.length}
            </Text>
            ,
          ],
        };
      }),
    [data.nodes],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate title={'Organizations'} buttonLabel="Create New Organization" onButtonClick={onOpen}>
      <CustomTable<TOrganization[], TSortingColumn>
        isLoading={isLoading}
        dataWithId={data.nodes}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={tableListItems}
        isSearching={false}
        noItemsMessage="No organizations created yet"
        columns={COLUMNS}
        hasRowSelect
      />
      <CreateOrganizationModal isOpen={isOpen} onClose={onClose} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/')({
  component: Organizations,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getOrganizations()),
});
