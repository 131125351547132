import {
  TMemberCare,
  TMemberCareFilter,
  TMemberCareFilterOption,
  TMemberCareInvite,
  TMemberCareOrgAssignment,
  TMemberCareMember,
} from '@/types/MemberCare.types';
import { usePaginatedSearch } from '../usePaginatedSearch';
import queryKeys from '@/constants/queryKeys';
import {
  reactivateMemberCarePost,
  assignOrgToMemberCarePost,
  getAllMemberCareSpecialists,
  getMemberCareSpecialistById,
  inviteMemberCareToPlatformPost,
  unassignOrgFromMemberCareDelete,
  deactivateMemberCarePost,
  getMemberCareUnassignedOrganizations,
  getMemberCareMembers,
  getMemberCareSpecialistsForOrganizations,
  getMemberCareMemberById,
} from '@/services/api/requests/member-care';
import { TOrganization } from '@/types/Organization.types';
import { TCustomAxiosError } from '@/services/api';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { usePagination } from '../usePagination';
import { TFilterParams, TSortingParams } from '@/types';
import { getAssessmentsForMemberCareMember } from '@/services/api/requests/assessment';

const useMemberCareSpecialists = ({
  filterOption,
  filterText,
  sortingColumn,
  sortingDirection,
}: TMemberCareFilter & TSortingParams) => {
  return usePaginatedSearch<TMemberCare[], TMemberCareFilterOption, string>({
    query: getAllMemberCareSpecialists,
    queryKey: queryKeys.memberCare.memberCareSpecialists,
    searchFilterType: filterOption ?? 'Email',
    searchFilterText: filterText,
    sortingColumn,
    sortingDirection,
  });
};

const useMemberCareProfile = (memberCareId: TMemberCare['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getMemberCareSpecialistById(memberCareId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useInviteMemberCareToPlatform = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberCareToInvite: TMemberCareInvite) => {
      const response = await inviteMemberCareToPlatformPost(memberCareToInvite);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberCare.inviteToPlatform] });

      toast({
        status: 'success',
        title: 'Success',
        description: 'Invitation sent successfully!',
      });

      onSuccessCallback?.();

    
      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

const useMemberCareUnassignedOrganizations = (
  memberCareId: TMemberCare['user_id'],
  // { sortingColumn, sortingDirection }: TSortingParams, // TODO: probably should add sorting later
) => {
  return usePagination<TOrganization[], string>({
    query: getMemberCareUnassignedOrganizations,
    queryKey: queryKeys.memberCare.unassignedOrgs,
    // sortingColumn,
    // sortingDirection,
    queryParams: memberCareId,
  });
};

const useAssignOrgToMemberCare = (
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, TMemberCareOrgAssignment, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (mcOrgAssignment: TMemberCareOrgAssignment) => {
      const response = await assignOrgToMemberCarePost(mcOrgAssignment);
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully assigned.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Assigning organization error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useUnassignOrgFromMemberCare = (
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, TMemberCareOrgAssignment, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (mcOrgAssignment: TMemberCareOrgAssignment) => {
      const response = await unassignOrgFromMemberCareDelete(mcOrgAssignment);
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully unassigned.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Assigning organization error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useReactivateMemberCare = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberCareId: string) => {
      await reactivateMemberCarePost(memberCareId);

      return memberCareId;
    },
    onSuccess: (memberCareId: string) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId] });

      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Care reactivation successful!',
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

const useDeactivateMemberCare = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberCareId: string) => {
      await deactivateMemberCarePost(memberCareId);

      return memberCareId;
    },
    onSuccess: (memberCareId: string) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId] });

      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Care deactivation successful!',
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

// const useMemberCareMembersWithSearch = ({ filterOption, filterText }: TMemberCareMemberFilter) => {
//   return usePaginatedSearch<TMemberCareMember[], TMemberCareMemberFilterOption, string>({
//     query: getMemberCareMembers,
//     queryKey: queryKeys.memberCare.memberCareSpecialists,
//     searchFilterType: filterOption ?? 'First name',
//     searchFilterText: filterText,
//   });
// };

const useMemberCareMembers = ({
  sortingColumn,
  sortingDirection,
  fromDate,
  toDate,
  scoreFilter,
  searchText,
}: TSortingParams & TFilterParams) => {
  return usePagination<TMemberCareMember[], TFilterParams>({
    query: getMemberCareMembers,
    queryKey: queryKeys.memberCare.memberCareSpecialists,
    sortingColumn,
    sortingDirection,
    queryParams: {
      fromDate,
      toDate,
      scoreFilter,
      searchText,
    },
  });
};

const useMemberCareSpecialistsForOrganizations = (orgId: string) => {
  return usePagination<TMemberCare[], { orgId: string }>({
    query: getMemberCareSpecialistsForOrganizations,
    queryKey: queryKeys.organization.memberCareSpecialists,
    queryParams: { orgId },
  });
};

const useMemberCareMemberProfile = (memberId: TMemberCareMember['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCaresMemberById, memberId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getMemberCareMemberById(memberId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useMemberCareMemberTimeline = (memberId: TMemberCareMember['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCaresMemberTimeline, memberId],
    staleTime: 0,
    queryFn: async () => {
      // TODO: we should fetch member-care timeline, once BE makes the API (probably MVP 1.2.)
      const response = await getAssessmentsForMemberCareMember(memberId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

export {
  useMemberCareSpecialists,
  useMemberCareProfile,
  useInviteMemberCareToPlatform,
  useMemberCareUnassignedOrganizations,
  useAssignOrgToMemberCare,
  useUnassignOrgFromMemberCare,
  useReactivateMemberCare,
  useDeactivateMemberCare,
  useMemberCareMembers,
  useMemberCareSpecialistsForOrganizations,
  useMemberCareMemberProfile,
  useMemberCareMemberTimeline,
};
