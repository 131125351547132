
import videoMaterials from '@/assets/video';
import ColorOverlay from '@/components/atoms/ColorOverlay';
import Header from '@/components/molecules/Header';
import { HEADER_HEIGHT, MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props = {
  title?: string;
  heroChildrenTopMargin?: number;
  showHeader?: boolean;
};

// A template for a page with a full background image;
// children is the children of the page overlaying on the background image
const BackgroundImagePage = ({
  children,
  title,
  heroChildrenTopMargin = HEADER_HEIGHT / 2,
}: PropsWithChildren<Props>) => {
  return (
    <VStack
      width={'100%'}
      height={'100vh'}
      maxHeight={'100vh'}
      backgroundImage={videoMaterials.manMeditatingPoster}
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      position={'relative'}
    >
      <Box width="100%" height={'100%'} zIndex={0} position={'absolute'}>
        <ColorOverlay color1Alpha={0.8} color1={'#2A00AE'} color2="#2A00AE" color2Alpha={0.4} height={'100vh'} />
        <ColorOverlay color1Alpha={0.1} color2Alpha={0} color1={'#000000'} height={'100vh'} />
      </Box>
      <Header variant='transparent' isSticky={false} hideButtons={false} />
      <Flex
        flexDirection="column"
        width="100vw"
        maxWidth={MAX_CONTAINER_WIDTH}
        height={'100vh'}
        maxHeight={'100vh'}
        overflow={'scroll'}
        className="no-scrollbar-container"
        paddingTop={{ base: heroChildrenTopMargin }}
        zIndex={1}
      >
        

        <Stack
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          background={'radial-gradient(50% 70% at 50% 100%, #FF8811 0%, rgba(255, 136, 17, 0) 100%)'}
          gap={0}
        >
          <Text variant="loraTitle" color="white" fontSize={'26px'}>
            {title}
          </Text>
          {children}
        </Stack>
      </Flex>
    </VStack>
  );
};

export default BackgroundImagePage;
