import {svgIconPaths} from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';
import {
  Button,
  Center,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

const boxShadowStyle =
  '0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03)';

interface Props {
  popoverContent: JSX.Element;
  isFilterActive: boolean;
  onFilterSubmit: () => void;
  submitButtonDisabled: boolean;
}

const FilterSelectPopover = ({ popoverContent, isFilterActive, onFilterSubmit, submitButtonDisabled }: Props) => {
  const { isOpen: isFilterOpen, onOpen, onClose } = useDisclosure();

  const localFilterSubmit = () => {
    onFilterSubmit();
    onClose();
  };

  return (
    <VStack position={'relative'}>
      {isFilterActive && (
        <Center
          position={'absolute'}
          right={0}
          top={'-10px'}
          zIndex={2}
          backgroundColor={'extra.lightRed'}
          borderRadius={'full'}
          width={'20px'}
          height={'20px'}
        >
          <Text variant={'urbanistExtraBoldSmall'} color={'white'}>
            1
          </Text>
        </Center>
      )}
      <Popover placement="bottom" isOpen={isFilterOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            width={'100px'}
            height={'54px'}
            cursor="pointer"
            _hover={{ backgroundColor: 'white', boxShadow: boxShadowStyle }}
            background={isFilterOpen || isFilterActive ? 'white' : 'transparent'}
            paddingY={'10px'}
            paddingX={'16px'}
            borderRadius={'8px'}
            boxShadow={isFilterOpen || isFilterActive ? boxShadowStyle : 'none'}
          >
            <HStack gap={'2px'}>
              <SvgIcon
                iconPath={svgIconPaths.filter}
                color={colors.text.darkBlue}
                size={14}
                style={{ marginTop: '3px', opacity: isFilterOpen ? 0.5 : 1 }}
              />
              <VStack flexGrow={1} gap={'3px'} alignItems={'flex-start'} marginLeft={'10px'}>
                <Text
                  fontSize={'14px'}
                  lineHeight={'16px'}
                  fontWeight={'700'}
                  opacity={isFilterOpen ? 0.5 : 1}
                  color={'text.darkBlue'}
                >
                  Filter
                </Text>
              </VStack>
            </HStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent borderRadius={'8px'} borderColor={'transparent'} width={'360px'} boxShadow={boxShadowStyle}>
          <VStack bg="white" borderRadius={'8px'} padding={'20px'}>
            {popoverContent}
            <CustomButton
              label="Apply"
              disabled={submitButtonDisabled}
              width={'100%'}
              height={'54px'}
              onClick={localFilterSubmit}
            />
          </VStack>
        </PopoverContent>
      </Popover>
    </VStack>
  );
};

export default FilterSelectPopover;
