import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate'
import { HStack } from '@chakra-ui/react'
import { createFileRoute, redirect } from '@tanstack/react-router'
import CustomButton from '@/components/atoms/CustomButton'
import { useState } from 'react'
import ActivatedUsersTable from '@/modules/user/ActivatedUsersTable'
import DeactivatedUsersTable from '@/modules/user/DeactivatedUsersTable'
import { TUserFilterOption } from '@/types/User.types'
import { USER_FILTER_BY_OPTIONS } from '@/constants/stringVars'
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars'
import SearchWithDropdownFilter from '@/components/molecules/SearchWithDropdownFilter'
import SidebarContentWrapper from '@/modules/sidebar/SidebarContentWrapper'

const Users = () => {
  const [usersSelection, setUsersSelection] = useState<
    'activated' | 'deactivated'
  >('activated')

  const [filterOption, setFilterOption] = useState<
    TUserFilterOption | undefined
  >()
  const [filterText, setFilterText] = useState('')

  const filterTextToSearch =
    filterText.length < MIN_CHARS_TO_DO_SEARCH ? '' : filterText

  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  return (
    <SidebarContentWrapper>
      <SidebarItemViewTemplate
        title={`${usersSelection === 'activated' ? 'Active' : 'Deactivated'} Users`}
      >
        <HStack width={'90%'} marginTop={'20px'} justifyContent={'space-between'}>
          <HStack
            backgroundColor={'white'}
            padding={'8px'}
            borderRadius={'8px'}
            gap={'10px'}
            boxShadow={'0px 2px 2px 0px #7C9EBC80'}
          >
            <CustomButton
              label={'Active'}
              onClick={() => setUsersSelection('activated')}
              backgroundColor={
                usersSelection === 'activated' ? 'primary.500' : 'white'
              }
              labelColor={
                usersSelection === 'activated' ? 'white' : 'primary.500'
              }
              isTransparent={usersSelection !== 'activated'}
            />
            <CustomButton
              label={'Deactivated'}
              onClick={() => setUsersSelection('deactivated')}
              backgroundColor={
                usersSelection === 'deactivated' ? 'primary.500' : 'white'
              }
              labelColor={
                usersSelection === 'deactivated' ? 'white' : 'primary.500'
              }
              isTransparent={usersSelection !== 'deactivated'}
            />
          </HStack>

          <SearchWithDropdownFilter
            filterOption={filterOption}
            filterOptions={USER_FILTER_BY_OPTIONS}
            filterText={filterText}
            setFilterOption={setFilterOption}
            setFilterText={setFilterText}
            isLoadingSearch={isLoadingSearch}
          />
        </HStack>
        {usersSelection === 'activated' ? (
          <ActivatedUsersTable
            filterOption={filterOption}
            filterText={filterTextToSearch}
            setIsLoadingSearch={setIsLoadingSearch}
          />
        ) : (
          <DeactivatedUsersTable
            filterOption={filterOption}
            filterText={filterTextToSearch}
            setIsLoadingSearch={setIsLoadingSearch}
          />
        )}
      </SidebarItemViewTemplate>
    </SidebarContentWrapper>
    
  )
}

export const Route = createFileRoute('/_authenticated/')({
  component: Users,
  beforeLoad: async ({ context: { auth } }) => {
    const isMemberCare = auth?.isMemberCare
    const isOnboarded = auth?.isOnboarded

    if (isMemberCare && !isOnboarded) {
      throw redirect({
        to: '/onboarding/member-care',
      })
    }

    if(isMemberCare && isOnboarded){
      throw redirect({
        to: '/member-care/members',
      })
    }

  },
})
