import { TMemberCareOnboarding } from '@/types/MemberCare.types';
import useToast from '../useToast';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { updateMemberCarePut } from '@/services/api/requests/member-care';
import { TCustomAxiosError } from '@/services/api';

const useOnboardMemberCare = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, TMemberCareOnboarding & { profilePhoto?: File }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (mc: TMemberCareOnboarding & { profilePhoto?: File }) => {
      const { profilePhoto, ...memberCareData } = mc;
      await updateMemberCarePut(memberCareData, profilePhoto);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Care successfully onboarded.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Care onboarding error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

export default useOnboardMemberCare;
