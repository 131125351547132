import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_onboarding')({
  beforeLoad: async ({ context: { auth } }) => {
    const isOnboarded = auth?.isOnboarded;

    if (isOnboarded) {
      throw redirect({ to: '/' });
    }
  },
});
