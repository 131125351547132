import { useCallback, useState } from 'react';

type TSortingDirection = 'asc' | 'desc';
const DEFAULT_SORTING_DIRECTION = 'desc';

interface Props<TColumn> {
  defaultSortingColumn: TColumn;
}

export const useTableColumnSort = <TColumn>({ defaultSortingColumn }: Props<TColumn>) => {
  const [sortingColumn, setSortingColumn] = useState<TColumn>(defaultSortingColumn);
  const [sortingDirection, setSortingDirection] = useState<TSortingDirection>(DEFAULT_SORTING_DIRECTION);

  const updateSortingColumn = useCallback(
    (clickedColumn: TColumn) => {
      if (clickedColumn === sortingColumn) {
        setSortingDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortingColumn(clickedColumn);
        setSortingDirection(DEFAULT_SORTING_DIRECTION);
      }
    },
    [sortingColumn],
  );

  return { sortingColumn, sortingDirection, updateSortingColumn };
};
