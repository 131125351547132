import CustomButton from '@/components/atoms/CustomButton';
import { Box, Center, HStack, Image, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import FilePicker from '@/components/atoms/FilePicker';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { svgIcons } from '@/assets/svg/icons';

const PROFILE_IMG_PLACEHOLDER_SIZE = 88;

interface UserProfilePhotoBoxProps {
  setProfilePhoto: (photo: File) => void;
  initialPreviewUrl?: string;
}

const UserProfilePhotoBox = ({ setProfilePhoto, initialPreviewUrl = '' }: UserProfilePhotoBoxProps) => {
  const [showUploadButton, setShowUploadButton] = useState(false);
  const inputProfilePhotoRef = useRef<HTMLInputElement>(null);

  const [preview, setPreview] = useState<string | null>(initialPreviewUrl);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const hasInitImagePreview = !!preview || !!initialPreviewUrl;

  const uploadPhotoWithLoading = useCallback(async (file: File | null) => {
    if (!file) return;

    setProfilePhoto(file);
  }, []);

  return (
    <Box
      height="130px"
      width={FORM_FIELD_WIDTH}
      padding={'20px'}
      boxShadow="0px 10px 80px 0px #00417926"
      background="white"
      borderRadius={'8px'}
      marginBottom={'10px'}
    >
      <Center>
        <HStack justifyContent={'space-between'} width={'100%'} marginTop={'-5px'}>
          <Box maxWidth={'150px'}>
            <Text variant={'urbanistSemiBold'} textAlign={'start'} marginBottom={'10px'}>
              Profile Photo
            </Text>
            <Text variant={'urbanistSmall'} color={'text.mediumBlue'}>
              Not smaller than 100x100px and not larger than 3MB.
            </Text>
          </Box>
          <Center
            borderRadius={'50%'}
            backgroundImage={preview ?? ''}
            height={'100px'}
            width={'110px'}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
            borderWidth={'5px'}
            borderColor={'white'}
            boxShadow={'0px 12px 30px 0px #0E00621A'}
            onMouseEnter={() => setShowUploadButton(true)}
            onMouseLeave={() => setShowUploadButton(false)}
            position={'relative'}
            cursor={'pointer'}
            onClick={() => inputProfilePhotoRef.current?.click()}
          >
            <Box position={'absolute'} opacity={showUploadButton ? 1 : 0} transition={'opacity 0.3s'}>
              <FilePicker
                inputRef={inputProfilePhotoRef}
                selectedFile={null}
                setSelectedFile={uploadPhotoWithLoading}
                setPreview={setPreview}
                accept="image/*"
              />
              <CustomButton
                // just sending in an empty function, because <Center> parent handles the click
                onClick={() => {}}
                label={'Upload'}
                backgroundColor="primary.600"
                hoverBackgroundColor="primary.600"
                labelHoverColor="white"
                labelColor="white"
              />
            </Box>

            {!hasInitImagePreview && (
              <Image
                alt="generic-profile"
                src={svgIcons.genericUser}
                height={PROFILE_IMG_PLACEHOLDER_SIZE}
                width={PROFILE_IMG_PLACEHOLDER_SIZE}
              />
            )}
          </Center>
        </HStack>
      </Center>
    </Box>
  );
};

export default UserProfilePhotoBox;
