import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  component: Outlet,
  beforeLoad: async ({ context: { auth } }) => {
    if (!auth?.isAuthenticated) {
      throw redirect({
        to: '/sign-in',
      });
    }
  },
});
