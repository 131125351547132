import { Flex } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import { useAuthContext } from '@/context/auth/useAuthContext';

const SidebarContentWrapper = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const { isOnboarded } = useAuthContext();

  return (
    <Flex w={'100%'} maxHeight={'100vh'}>
      {isOnboarded && <Sidebar />}
      <Flex width={'100%'} overflow={'scroll'} padding={isOnboarded ? '30px' : 0} paddingBottom={0}>
        {children}
      </Flex>
    </Flex>
  );
};

export default SidebarContentWrapper;
