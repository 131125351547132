import { FileRoutesByTo } from '../../routeTree.gen.ts';

export type TRoutes = keyof FileRoutesByTo;

export type TRoutesWithLabels = { title: string; href: TRoutes }[];

// Admin
export type TAdminRoutes = {
  assessments: TRoutes;
  eula: TRoutes;
  organizations: TRoutes;
  // users: TRoutes;
  memberCare: TRoutes;
};

export const adminRoutes: TAdminRoutes = {
  assessments: '/assessments',
  eula: '/eula',
  organizations: '/organizations',
  // users: '/',
  memberCare: '/member-care',
};

export const adminRoutesWithLabels: TRoutesWithLabels = [
  // { title: 'Users', href: adminRoutes.users },
  { title: 'Organizations', href: adminRoutes.organizations },
  { title: 'Member Care', href: adminRoutes.memberCare },
  { title: 'Assessments', href: adminRoutes.assessments },
  { title: 'EULA', href: adminRoutes.eula },
];

// Member Care
export type TMemberCareRoutes = { memberCareMembers: TRoutes }; // memberCareDashboard: TRoutes;

export const memberCareRoutes: TMemberCareRoutes = {
  // memberCareDashboard: '/member-care/dashboard',
  memberCareMembers: '/member-care/members',
};

export const memberCareRoutesWithLabels: TRoutesWithLabels = [
  // { title: 'M. Care Dashboard', href: memberCareRoutes.memberCareDashboard },
  { title: 'My Members', href: memberCareRoutes.memberCareMembers },
];

export const nonAuthRoutes: { signIn: TRoutes } = {
  signIn: '/sign-in',
};

const appRoutes = {
  ...adminRoutes,
  ...memberCareRoutes,
  ...nonAuthRoutes,
};

export default appRoutes;
