import { APP_NAME } from '@/constants/stringVars';

const onboardingMessages = {
  memberCareOnboardingTitle: `We’re delighted to have you onboard.`,
  userOnboardingDescription: `We invite you to join us and let ${APP_NAME} help your organization with everything related to mental health.`,
  proceed: 'Proceed',
  yourEmailAddress: 'Your email address',
  emailSuccessfullyVerified: 'You have successfully verified your email.',
  emailNotVerifiedSuccessfully: 'Wrong credentials or expired token',
  checkEmailOrContactAdmin: 'Please check your email or contact your administrator.',
  createYourPassword: 'Next, create your password.',
  passwordSetupUnsuccessful: 'Password setup unsuccessful.',
  passwordMustContain: 'Password must contain:',
  password8chars: '8+ characters',
  passwordLowercase: '1 lowercase',
  passwordUppercase: '1 uppercase letter',
  passwordNumberOrSpecialChar: '1 number or special character',
  listItemDot: '•',
  iAgreeTo: 'I agree to',
  termsOfUse: 'Terms of Use',
  and: 'and',
  privacyPolicy: 'Privacy Policy',
  termsOfInfoTooltip: `I agree that I have read and agree to the User Agreement that outlines what products, services, and features are available to me. Along with the Data Use, Privacy Policy and HIPAA disclosure. \n${APP_NAME} is a software provider, and will operate as an associate of various providers as we coordinate the care management efforts along your journey to wholeness.`,
  tellUsMore: 'Tell us a bit more about yourself',
  tellUsMoreDescription: `We’re about to ask you for your personal information. Your personal information will always be kept 100% confidential from your employer. We understand your apprehension in providing personal information, however, it is necessary to assure you have a seamless experience regarding access to, and payment of, therapy and wellness support. Rest assured we are HIPAA compliant and have all the required firewalls and protocols to keep your information protected and secure.`,
  memberCareOnboardingDescription: `It is a great pleasure to have one more Member Care Specialist at ${APP_NAME}.`,
  goBack: 'Go back',
  continue: 'Continue',
};

export default onboardingMessages;
