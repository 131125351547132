import { useLayoutEffect, useState } from 'react';
import { createFileRoute, Link, redirect, useNavigate, useRouter } from '@tanstack/react-router';
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Link as ChakraLink,
  Stack,
  Text,
  Image,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuthContext } from '@/context/auth/useAuthContext';
import { TCustomAxiosError } from '@/services/api';
import appMessages from '@/messages';
import authMessages from '@/messages/auth';
import iconsPng from '@/assets/img/png/icons';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import authRoutes from '@/constants/routes/auth';
import CustomButton from '@/components/atoms/CustomButton';
import BackgroundImagePage from '@/components/templates/BackgroundImagePage';
import Header from '@/components/molecules/Header';

type FormData = {
  email: string;
  password: string;
};

// NOTE: If we expect some custom validation rules, consider moving yup instance to separate file and import from there
const schema = yup
  .object({
    email: yup.string().email('Please enter valid email address.').required('This field is required.'),
    password: yup.string().required('This field is required.'),
  })
  .required();

const SignIn = () => {
  const { link } = Route.useSearch();
  const router = useRouter();
  const navigate = useNavigate({ from: '/sign-in' });
  const { signIn, isAuthenticated, isSuperAdmin, isMemberCare } = useAuthContext();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const [showPassword, setShowPassword] = useState(false);

  const { mutateAsync: handleSignIn, isPending: isHandleSignInPending } = useMutation({
    mutationFn: async (data: FormData) => {
      await signIn(data.email, data.password);
      router.invalidate();
    },
    onError: (err: TCustomAxiosError) => {
      // NOTE: this is example of handling api error beside form error. Remove if not needed
      toast({
        title: 'An error occurred.',
        description: err.errors[0].message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  useLayoutEffect(() => {
    if (isAuthenticated) {
      if (link) {
        window.location.replace(link);
        return;
      }

      if (isSuperAdmin) {
        navigate({ to: '/' });
        return;
      }

      if (isMemberCare) {
        navigate({ to: '/member-care/members' });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, navigate]);

  return (
    <BackgroundImagePage showHeader={false} heroChildrenTopMargin={0}>
      <Header variant='transparent' isSticky={false} />
      <VStack justifyContent={'center'} alignItems={'center'} maxHeight={'100vh'} height={'100vh'}>
        <Text variant={'loraTitle'} position={'relative'} color={'white'} maxWidth={'400px'}>
          {authMessages.signIn}
        </Text>
        <VStack minW={{ md: FORM_FIELD_WIDTH }} marginTop={'10px'} alignItems={'center'}>
          <form onSubmit={handleSubmit((data) => handleSignIn(data))}>
            <Stack spacing={4} p="1rem">
              <FormControl isInvalid={!!errors.email}>
                <Input className="custom-input" {...register('email')} placeholder={appMessages.email} />
                <Text color={'extra.red'}>{errors.email?.message ?? ''}</Text>
              </FormControl>
              <FormControl isInvalid={!!errors.password}>
                <InputGroup>
                  <Input
                    className="custom-input"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={appMessages.password}
                    {...register('password')}
                  />
                  <InputRightElement width="4rem" height={'100%'}>
                    <Button onClick={() => setShowPassword(!showPassword)} backgroundColor={'transparent'}>
                      <Image
                        src={showPassword ? iconsPng.eyeClosed : iconsPng.eyeOpened}
                        width={25}
                        height={25}
                        alt={showPassword ? 'eyeOpened' : 'eyeClosed'}
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color={'extra.red'}>{errors.password?.message ?? ''}</Text>
              </FormControl>
              <CustomButton
                label={authMessages.signIn}
                buttonType="submit"
                backgroundColor="secondary.500"
                width="300px"
                isLoading={isHandleSignInPending}
                disabled={!isValid}
                style={{ marginTop: '40px' }}
              />
            </Stack>
          </form>
          <HStack width={'100%'} justifyContent={'center'}>
            <ChakraLink
              as={Link}
              to={authRoutes.forgotPassword}
              color="text.veryLightBlue"
              fontSize={'15px'}
              fontWeight={'600'}
              lineHeight={'20px'}
              textAlign={'center'}
            >
              {authMessages.forgotPassword}
            </ChakraLink>
          </HStack>
        </VStack>
      </VStack>
    </BackgroundImagePage>
  );
};

export const Route = createFileRoute('/sign-in/')({
  component: SignIn,
  validateSearch: (search: Record<string, unknown>) => {
    // Validate search params here. We only want valid url as link param
    if (search.link === 'https://www.blablabla.com/contact/support') {
      return {
        link: search.link,
      };
    }

    delete search.link;
    return {};
  },
  beforeLoad: async ({ context: { auth } }) => {
    const isAuthenticated = auth?.isAuthenticated;
    if (isAuthenticated) {
      throw redirect({
        to: '/',
      });
    }
  },
});
